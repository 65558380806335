window.validateAnswer = function(number, local_storage_version) {
  const answers = document.getElementById('answer-input').value;
  if (answers.split(' ').filter((el) => el !== '').length === 0) return;
  document.getElementById('card__submit').style.display = 'none';
  document.getElementById('card__spinner').style.display = 'inline-block';
  Rails.ajax({
    url: '/validate_answers',
    type: 'POST',
    data: 'card_number=' + number + '&answers=' + answers,
    success: function(data) {
      console.log(data);
      setAnswer(number, data, local_storage_version);
    }
  });
};

window.addEventListener('load', function(event) {
  initProgressBar();
  initCorrectCound();
});

initCorrectCound = function() {
  const local_storage_version = document.getElementById('local_storage_version').value;
  const card_count = document.getElementById('card_count').value;
  let correct = 0;
  for (var i = 1; i <= card_count; i++) {
    tileStatus = localStorage.getItem('answer_' + local_storage_version + '_' + i);
    if (tileStatus === '1') {
      correct = correct + 1;
    }
  }
  const countElement = document.getElementById('correct-count');
  if (countElement) countElement.innerHTML = correct;
};

initProgressBar = function() {
  const local_storage_version = document.getElementById('local_storage_version').value;
  const card_count = document.getElementById('card_count').value;
  for (var i = 1; i <= card_count; i++) {
    let tile = document.getElementById('tile' + i);
    tileStatus = localStorage.getItem('answer_' + local_storage_version + '_' + i);
    if (tileStatus === '0') {
      tile.className += ' progress__bar-tile--wrong';
    } else if (tileStatus === '1') {
      tile.className += ' progress__bar-tile--correct';
    } else {
      tile.className += ' progress__bar-tile--neutral';
    }
  }
};

// states
// 0 = wrong
// 1 = correct
// null = not answered
function logAnswerResult(number, state, local_storage_version) {
  const answerId = 'answer_' + local_storage_version + '_' + number;
  localStorage.setItem(answerId, state);
}

window.deleteAnswerLog = function() {
  const local_storage_version = document.getElementById('local_storage_version').value;
  const card_count = document.getElementById('card_count').value;
  for (var i = 1; i <= card_count; i++) {
    tileStatus = localStorage.removeItem('answer_' + local_storage_version + '_' + i);
  }
  alert('Antworten wurden erfolgreich zurückgesetzt.');
  window.location.replace('/');
};

function setAnswer(card_id, data, local_storage_version) {
  document.getElementById('card__question-section').style.display = 'none';
  document.getElementById('card__answer-section').style.display = 'block';
  document.getElementById('answer-story').innerHTML = data.answer_story.body;
  if (data.missing.length === 0 && data.wrong.length === 0) {
    logAnswerResult(card_id, 1, local_storage_version);
    document.getElementsByClassName('card__success')[0].style.display = 'block';
    document.getElementsByClassName('card__failure')[0].style.display = 'none';
  } else {
    logAnswerResult(card_id, 0, local_storage_version);
    document.getElementsByClassName('card__success')[0].style.display = 'none';
    document.getElementsByClassName('card__failure')[0].style.display = 'block';
  }

  if (data.missing.length > 0) {
    document.getElementsByClassName('card__missing-answers-text')[0].style.display = 'block';
  }

  if (data.optional.length > 0) {
    document.getElementsByClassName('card__optional-answers-text')[0].style.display = 'block';
  }

  data.correct.forEach((answer) => {
    var div = document.createElement('div');
    div.className = 'card__answer card__answer--correct';
    div.innerHTML = answer;
    document.getElementsByClassName('card__answers')[0].appendChild(div);
  });
  data.wrong.forEach((answer) => {
    var div = document.createElement('div');
    div.className = 'card__answer card__answer--wrong';
    div.innerHTML = answer;
    document.getElementsByClassName('card__answers')[0].appendChild(div);
  });
  data.missing.forEach((answer) => {
    var div = document.createElement('div');
    div.className = 'card__answer card__answer--missing';
    div.innerHTML = answer;
    document.getElementsByClassName('card__missing-answers')[0].appendChild(div);
  });
  data.optional.forEach((answer) => {
    var div = document.createElement('div');
    div.className = 'card__answer card__answer--missing';
    div.innerHTML = answer;
    document.getElementsByClassName('card__missing-answers')[0].appendChild(div);
  });
}
